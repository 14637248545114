export const fileDialogOptionsDefaults = {
    SREQ: {
        multiple: true,
        accept: [
            'image/jpeg',
            'image/png',
            'application/pdf',
            'application/vnd.ms-powerpoint',
            'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        ],
    },
};

export const downloadFile = (url, name) => {
    const a = document.createElement('a');
    a.href = url;
    a.download = name;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
};
