<template>
    <div class="grid grid-cols-3 gap-2">
        <template v-if="editable">
            <input
                ref="fileInput"
                class="hidden"
                type="file"
                :multiple="multiple"
                :accept="accept"
                @change="handleFileInputChange"
            />

            <div
                class="add-new"
                @click="$refs.fileInput.click()"
            >
                <icon
                    name="plus"
                    class="block h-4 w-4"
                />
                <div class="text-md2 font-normal">{{ $t('attachments.add_new') }}</div>
            </div>
        </template>
        <AttachmentTile
            v-for="(item, index) of items"
            :key="item.id"
            :item="item"
            @click="openFull(index)"
            @clickDownload="download"
            @clickDelete="removeItem"
        />

        <AttachmentsGallery
            v-if="showGallery"
            :items="items"
            :open-on="openOn"
            @deleteItem="removeItem"
            @close="showGallery = false"
        />

        <loader
            :loading="loading"
            backdrop
        />
    </div>
</template>

<script>
import { v4 } from 'uuid';
import AttachmentsGallery from '@/components/ui/AttachmentsGallery';
import NotifyMixin from '@/mixins/NotifyMixin';
import { downloadFile } from '@/utils/files';
import Loader from '@/components/ui/Loader';
import AttachmentTile from '@/components/ui/atts/AttachmentTile';
import Icon from '@/components/ui/Icon';

export default {
    name: 'AttachmentsPreviews',

    components: { Icon, AttachmentTile, Loader, AttachmentsGallery },

    mixins: [NotifyMixin],

    props: {
        items: {
            type: Array,
            required: true,
        },

        editable: {
            type: Boolean,
            default: false,
        },

        fileDialogOptions: {
            type: Object,
            default: () => ({}),
        },
    },

    emits: ['addNewClick', 'update:items'],

    data() {
        return {
            showGallery: false,
            openOn: 0,
            loading: false,
        };
    },

    computed: {
        multiple() {
            return this.fileDialogOptions?.multiple;
        },

        accept() {
            return this.fileDialogOptions?.accept;
        },
    },

    methods: {
        openFull(index) {
            this.openOn = index;
            this.showGallery = true;
        },

        async download(id) {
            this.loading = true;
            try {
                const [item] = await this.$attachmentsDataProvider.getList('attachments', {
                    ids: [id],
                    preview: false,
                });
                downloadFile(item.url, item.filename);
            } catch (error) {
                this.notifyError(error.message);
            }
            this.loading = false;
        },

        handleFileInputChange(event) {
            this.$emit('addNewClick');
            this.$emit('update:items', [...Array.from(event.target.files).map(f => this._newFileToAttachment(f)), ...this.items]);
            this.$refs.fileInput.value = null;
        },

        _newFileToAttachment(file) {
            return {
                file,
                id: v4(),
                filename: file.name,
                contentType: file.type,
                size: file.size,
                inProgress: false,
                error: null,
                retry: null,
                url: URL.createObjectURL(file),
                uploaded: false,
                attached: false,
                action: 'delete',
            };
        },

        removeItem(itemId) {
            if (!this.editable) return;

            this.$emit(
                'update:items',
                this.items.filter(({ id }) => id !== itemId)
            );
        },
    },
};
</script>

<style scoped>
.add-new {
    @apply h-20 rounded-lg border border-dashed flex flex-col items-center justify-center cursor-pointer;
    @apply bg-purple-50 border-purple-500 text-purple-900;
}
</style>
