<template>
    <div
        v-if="innerItems.length !== 0"
        class="fixed left-0 top-0 w-full h-full bg-white z-50 flex flex-col"
    >
        <div class="gallery-header">
            <button
                type="button"
                @click="$emit('close')"
            >
                <icon
                    name="arrow-left"
                    class="block h-6 w-6"
                />
            </button>
            <div class="flex-grow mx-3 text-left font-ffdin font-medium text-sm whitespace-nowrap overflow-hidden overflow-ellipsis">
                {{ activeItem.type === 'image' ? activeItem.filename : '' }}
            </div>
            <button
                type="button"
                @click="$emit('close')"
            >
                <icon
                    name="close_2"
                    class="block w-3.5 h-3.5"
                />
            </button>
        </div>
        <div class="flex-grow overflow-auto">
            <div class="h-full">
                <div
                    ref="scrollContainer"
                    class="scroll-container"
                    dir="ltr"
                    @scroll="handleScroll"
                >
                    <div
                        v-for="item of innerItems"
                        :key="item.id"
                        class="relative"
                    >
                        <div
                            v-if="item.type === 'image' && item.objectURL"
                            class="w-full h-full flex items-center justify-center"
                        >
                            <img
                                class="max-w-full max-h-full"
                                :src="item.objectURL"
                                :alt="item.filename"
                            />
                        </div>
                        <div
                            v-else
                            class="flex flex-col items-center justify-center w-full h-full"
                        >
                            <div class="relative block w-auto h-16 mb-4">
                                <icon
                                    name="file"
                                    class="block w-full h-full"
                                />
                                <div class="absolute top-1/2 w-full uppercase font-ffdin font-bold text-brown-600">
                                    <div class="text-center file-extension">{{ item.extension }}</div>
                                </div>
                            </div>
                            <div class="text-lg font-sofia mb-1 max-w-full overflow-hidden overflow-ellipsis text-center">
                                {{ item.filename }}
                            </div>
                            <div class="text-md text-gray-500 uppercase">{{ bytesToReadable(item.size) }}</div>
                        </div>

                        <button
                            v-if="item.action === 'delete'"
                            type="button"
                            class="delete-button"
                            @click="deleteItem(item.id)"
                        >
                            <icon
                                name="trash"
                                class="w-3 h-3"
                            />
                        </button>
                        <button
                            v-if="item.uploaded"
                            type="button"
                            class="download-button"
                            @click="download(item)"
                        >
                            <icon name="download_2" />
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <loader
            :loading="loading"
            backdrop
        />
    </div>
</template>

<script>
import Icon from '@/components/ui/Icon';
import { bytesToReadable } from '@/utils/measurements';
import Axios from 'axios';
import NotifyMixin from '@/mixins/NotifyMixin';
import Loader from '@/components/ui/Loader';
import { downloadFile } from '@/utils/files';

const mimeTypeToInnerType = contentType => {
    const [type, subtype] = contentType.split('/');
    return type === 'image' ? type : subtype === 'pdf' ? subtype : 'other';
};

export default {
    name: 'AttachmentsGallery',

    components: { Loader, Icon },

    mixins: [NotifyMixin],

    props: {
        items: {
            type: Array,
            required: true,
        },

        openOn: {
            type: Number,
            default: 0,
        },
    },

    emits: ['deleteItem', 'close'],

    data() {
        return {
            activeIndex: 0,
            innerItems: [],
            loading: false,
        };
    },

    computed: {
        activeItem() {
            return this.innerItems[this.activeIndex];
        },
    },

    watch: {
        activeItem() {
            if (this.activeItem.type === 'image' && !this.activeItem.objectURL) {
                this.prepareItemObjectURL(this.activeItem);
            }
        },
    },

    mounted() {
        this.innerItems = this.items.map(item => {
            const parts = item.filename.split('.');
            const extension = parts?.[parts.length - 1] || '';
            return {
                ...item,
                extension,
                type: mimeTypeToInnerType(item.contentType),
            };
        });

        this.activeIndex = this.openOn;
        this.$nextTick(() => {
            this.$refs.scrollContainer.scrollLeft = this.$refs.scrollContainer.clientWidth * this.activeIndex;
        });
    },

    beforeUnmount() {
        this.innerItems.forEach(({ objectURL }) => URL.revokeObjectURL(objectURL));
    },

    methods: {
        bytesToReadable,

        handleScroll(event) {
            const { scrollLeft, clientWidth } = event.target;

            if (scrollLeft % clientWidth === 0) {
                this.activeIndex = scrollLeft / clientWidth;
            }
        },

        async prepareItemObjectURL(item) {
            if (item.file) {
                item.objectURL = URL.createObjectURL(item.file);
                return;
            }

            this.loading = true;

            try {
                try {
                    item.objectURL = URL.createObjectURL(
                        (
                            await Axios.get(item.url, {
                                responseType: 'blob',
                                headers: { 'Cache-Control': 'no-cache' },
                            })
                        ).data
                    );
                } catch (error) {
                    const [refreshed] = await this.$attachmentsDataProvider.getList('attachments', {
                        ids: [item.id],
                        preview: false,
                    });
                    item.objectURL = URL.createObjectURL((await Axios.get(refreshed.url, { responseType: 'blob' })).data);
                } finally {
                    this.$forceUpdate();
                }
            } catch (error) {
                this.notifyError(error.message);
            }

            this.loading = false;
        },

        async download(item) {
            if (!item.objectURL) {
                await this.prepareItemObjectURL(item);
            }

            if (item.objectURL) {
                downloadFile(item.objectURL, item.filename);
            }
        },

        deleteItem(id) {
            this.$emit('deleteItem', id);
            this.$emit('close');
        },
    },
};
</script>

<style scoped>
.scroll-container {
    @apply flex overflow-auto w-full h-full;
    flex: none;
    flex-flow: row nowrap;
    scroll-snap-type: x mandatory;
}

.scroll-container > div {
    @apply flex items-center justify-center w-full h-full;
    scroll-snap-align: center;
    flex: none;
}

.file-extension {
    @apply text-purple-600;
}

.gallery-header {
    @apply flex items-center bg-purple-600 text-white px-5 py-5;
}

.delete-button,
.download-button {
    @apply absolute bottom-4 w-10 h-10 flex items-center justify-center rounded-xl border bg-white;
}
.delete-button {
    @apply left-4 border-red-600 text-red-600;
}
.download-button {
    @apply right-4 border-black text-black;
}
</style>
