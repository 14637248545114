<template>
    <div
        class="attachment-tile"
        :class="isImage ? 'rounded-lg' : 'rounded'"
        :style="inlineStyles"
        @click="$emit('click')"
    >
        <div class="flex items-center justify-between">
            <div class="flex-grow flex">
                <div
                    v-if="!isImage"
                    class="filetype-icon"
                >
                    {{ extension }}
                </div>
            </div>
            <icon
                v-if="item.action === 'download'"
                name="download"
                class="w-3 h-3 text-black"
                @click.stop="$emit('clickDownload', item.id)"
            />
            <span
                v-else-if="item.action === 'delete'"
                class="block w-5 h-5 bg-white text-gray-500 rounded-full p-0.5"
                @click.stop="$emit('clickDelete', item.id)"
            >
                <icon name="close" />
            </span>
            <span
                v-else-if="item.action === 'retry-delete-menu'"
                class="relative block w-5 h-5 bg-red-200 text-red-600 rounded-full"
                @click.stop="showRetryDialog = true"
            >
                <icon
                    name="exclamation-circle"
                    class="text-red-600"
                />
                <span
                    v-if="showRetryDialog"
                    class="retry-dialog"
                >
                    <button
                        type="button"
                        class="mb-3.5 text-black whitespace-nowrap"
                        @click.stop="retryItemUpload(item)"
                    >
                        <icon
                            name="retry"
                            class="inline-block w-3 h-3 mr-4"
                        />
                        {{ $t('app.retry_action') }}
                    </button>
                    <button
                        type="button"
                        class="text-red-600 whitespace-nowrap"
                        @click.stop="$emit('clickDelete', item.id)"
                    >
                        <icon
                            name="trash"
                            class="inline-block w-3 h-3 mr-4"
                        />
                        {{ $t('app.delete_action') }}
                    </button>
                </span>
            </span>
        </div>
        <div
            v-if="!isImage"
            class="filename"
        >
            {{ item.filename }}
        </div>

        <div
            v-if="item.inProgress"
            class="loading-overlay"
        >
            {{ $t('app.content_loading') }}
        </div>
    </div>
</template>

<script>
import Icon from '@/components/ui/Icon';
export default {
    name: 'AttachmentTile',

    components: { Icon },

    props: {
        item: {
            type: Object,
            required: true,
        },
    },

    emits: ['click', 'clickDownload', 'clickDelete'],

    data() {
        return {
            showRetryDialog: false,
        };
    },

    computed: {
        isImage() {
            return this.item.contentType.includes('image');
        },

        extension() {
            const parts = this.item.filename.split('.');
            return parts?.[parts.length - 1] || '';
        },

        inlineStyles() {
            // prettier-ignore
            return this.isImage
                ? {
                    'background-size': 'cover',
                    'background-position': 'center',
                    'background-image': `url(${this.item?.previewUrl || this.item?.url})`,
                }
                : {};
        },
    },

    methods: {
        retryItemUpload(item) {
            this.showRetryDialog = false;
            item.retry();
        },
    },
};
</script>

<style scoped>
.attachment-tile {
    @apply h-20 rounded bg-gray-200 border border-gray-300 p-2 flex flex-col justify-between relative;
}

.filetype-icon {
    @apply inline-block text-3xs leading-none font-ffdin font-bold uppercase bg-purple-600 text-white p-0.5 pt-1 pr-1 rounded-sm;
    clip-path: polygon(0% 0%, 0% 0%, calc(100% - 5px) 0%, 100% 5px, 100% 100%, 100% 100%, 0% 100%, 0 100%);
}

.filename {
    @apply text-3xs font-ffdin font-medium;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.retry-dialog {
    @apply absolute right-0 flex flex-col bg-white border rounded p-4 pr-6 text-md;
}

.loading-overlay {
    @apply absolute flex items-center justify-center bg-white opacity-80 text-black;
    left: -1px;
    top: -1px;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
}
</style>
